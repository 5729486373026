/*
 * @Author: bowang
 * @Date: 2023-01-10 11:21:25
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-03-14 13:59:20
 */

import reachShow from 'ssr-common/vue/directive/reach-show';

import {BRAND} from 'mabilis-cash/common/constant/config';

import generateEvent from './event';
import eventV2 from './event-v2';

const event = generateEvent(BRAND);

export default {

    install(Vue) {
        Vue.directive('yqg-event', event);
        Vue.directive('event', eventV2);
        Vue.directive('reach-show', reachShow);
    }
};
