import UAParser from 'ua-parser-js';
export var getUa = function () {
    var _a, _b;
    return (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) !== null && _b !== void 0 ? _b : '';
};
export var parserUa = function (ua) {
    var parser = new UAParser(ua);
    return parser.getResult();
};
export var getBrowserInfo = function (ua) {
    var result = parserUa(ua);
    return {
        browserInfo: result
    };
};
