import { isServer } from 'ssr-common/constant/config';
var genKey = function () {
    var token = '';
    var codeAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 0; i < 8; i += 1) {
        token += codeAlphabet[Math.floor(Math.random() * 26)];
    }
    return "".concat(token, "-").concat(Date.now());
};
export var getKey = function () {
    if (isServer())
        return '';
    var userKey = window.localStorage.getItem('userKey');
    if (!userKey) {
        userKey = genKey();
        window.localStorage.setItem('userKey', userKey);
    }
    return userKey;
};
export default genKey;
