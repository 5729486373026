/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:54
 */

import Vue from 'vue';
import Vuex, {Store} from 'vuex';

import baseData from './module/base-data';
import contact from './module/contact';
import userStatus from './module/user-status';
Vue.use(Vuex);

export const createStore = () => new Store({
    modules: {
        baseData,
        contact,
        userStatus
    }
});
