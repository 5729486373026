/*
 * @Author: qxy
 * @Date: 2019-08-12 16:56:42
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 18:44:02
 */
/* eslint-disable max-len */

export default {
    name: 'card-01',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M28.01 56.024c-5.089 0-5.849-3.372-5.849-5.382V28.424c0-4.69 4.091-5.196 5.85-5.196 1.843 0 6.13.506 6.13 5.196v22.218c0 2.01-.797 5.382-6.13 5.382M28.01 3C20.702 3 13.995 5.322 9.126 9.54 3.81 14.14 1 20.575 1 28.144v22.871c0 7.57 2.81 14.004 8.126 18.606 4.87 4.216 11.576 6.538 18.885 6.538 7.423 0 14.2-2.32 19.08-6.536 5.31-4.585 8.117-11.02 8.117-18.608V28.144c0-7.588-2.806-14.022-8.116-18.607C42.21 5.322 35.434 3 28.01 3m0 55.67c4.947 0 8.775-2.707 8.775-8.028V28.424c0-5.135-3.828-7.842-8.775-7.842-4.761 0-8.496 2.707-8.496 7.842v22.218c0 5.321 3.735 8.028 8.496 8.028m0-53.024c13.536 0 24.552 8.215 24.552 22.498v22.871c0 14.283-11.016 22.499-24.552 22.499-13.256 0-24.365-8.216-24.365-22.499V28.144c0-14.283 11.109-22.498 24.365-22.498M92.943 4.214H67.832a2.645 2.645 0 0 0-2.646 2.645v13.536a2.645 2.645 0 0 0 2.646 2.646h6.222v32.89h-7.063a2.645 2.645 0 0 0-2.645 2.646v13.63a2.645 2.645 0 0 0 2.645 2.645h35.848a2.645 2.645 0 0 0 2.645-2.646v-13.63a2.646 2.646 0 0 0-2.645-2.645h-7.25V6.86a2.645 2.645 0 0 0-2.646-2.645m0 2.645v51.718h9.896v13.63H66.99v-13.63H76.7V20.395h-8.868V6.86h25.111'
                }
            }
        ]
    }
};
