/*
 * @Author: qxy
 * @Date: 2019-08-12 16:56:42
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 18:44:01
 */
/* eslint-disable max-len */

export default {
    name: 'card-04',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M27.26 56.357c-5.137 0-5.904-3.369-5.904-5.377V28.784c0-4.685 4.129-5.191 5.904-5.191 1.86 0 6.186.506 6.186 5.19V50.98c0 2.008-.804 5.377-6.186 5.377m0-52.972c-7.376 0-14.145 2.32-19.06 6.532C2.836 14.515 0 20.942 0 28.504v22.849c0 7.561 2.836 13.989 8.2 18.586 4.915 4.213 11.684 6.532 19.06 6.532 7.492 0 14.33-2.318 19.257-6.53 5.359-4.58 8.191-11.008 8.191-18.588V28.504c0-7.58-2.832-14.008-8.191-18.589-4.926-4.21-11.765-6.53-19.257-6.53m0 55.615c4.993 0 8.856-2.705 8.856-8.02V28.784c0-5.13-3.863-7.834-8.856-7.834-4.805 0-8.574 2.705-8.574 7.834V50.98c0 5.315 3.77 8.02 8.574 8.02m0-52.972c13.66 0 24.778 8.207 24.778 22.476v22.849c0 14.268-11.117 22.476-24.778 22.476-13.379 0-24.59-8.208-24.59-22.476V28.504c0-14.269 11.211-22.476 24.59-22.476M84.77 44.502l4.059-7.7-.471 7.7H84.77zM105.734 5h-17.97c-.952 0-1.831.512-2.303 1.341L59.348 52.225A2.665 2.665 0 0 0 59 53.542v7.319a2.656 2.656 0 0 0 2.652 2.659h25.892v9.82A2.656 2.656 0 0 0 90.197 76h15.536a2.656 2.656 0 0 0 2.652-2.66v-9.82h2.963A2.656 2.656 0 0 0 114 60.86V47.162a2.656 2.656 0 0 0-2.652-2.659h-2.963V7.66A2.656 2.656 0 0 0 105.733 5zM92.069 27.27h-1.217L80.37 47.16h10.482l1.217-19.891zm13.664-19.61V47.16h5.615v13.7h-5.615v12.48H90.197V60.86H61.652v-7.319L87.764 7.66h17.969z'
                }
            }
        ]
    }
};
