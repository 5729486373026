/*
 * @Author: qxy
 * @Date: 2019-08-12 16:56:42
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 18:03:52
 */
/* eslint-disable max-len */

export default {
    name: 'card-03',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M28.097 56.909c-5.07 0-5.827-3.365-5.827-5.37v-22.17c0-4.68 4.075-5.186 5.827-5.186 1.836 0 6.107.506 6.107 5.185v22.17c0 2.006-.794 5.37-6.107 5.37m0-52.908c-7.28 0-13.961 2.317-18.812 6.524C3.989 15.116 1.19 21.536 1.19 29.09v22.822c0 7.553 2.8 13.973 8.095 18.565C14.135 74.683 20.817 77 28.097 77c7.395 0 14.146-2.316 19.008-6.522 5.29-4.575 8.085-10.996 8.085-18.567V29.089c0-7.571-2.795-13.992-8.085-18.567C42.243 6.317 35.493 4 28.097 4m0 55.549c4.93 0 8.742-2.701 8.742-8.01v-22.17c0-5.124-3.813-7.825-8.742-7.825-4.742 0-8.462 2.701-8.462 7.824v22.17c0 5.31 3.72 8.01 8.462 8.01m0-52.908c13.485 0 24.458 8.197 24.458 22.45v22.82c0 14.252-10.973 22.45-24.458 22.45-13.205 0-24.271-8.198-24.271-22.45V29.09c0-14.253 11.066-22.45 24.271-22.45M108.21 5H66.39a2.656 2.656 0 0 0-2.663 2.648v14.854a2.656 2.656 0 0 0 2.664 2.647h14.754l-6.912 14.333a2.637 2.637 0 0 0-.262 1.144v2.242c0 .72.295 1.41.818 1.909a2.67 2.67 0 0 0 1.95.737c1.268-.05 2.565-.1 3.857-.1 8.253 0 9.959 2.378 10.009 6.22-.119 4.706-3.235 5.216-4.573 5.216-4.145 0-4.722-2.061-4.762-3.856a2.656 2.656 0 0 0-2.662-2.59H63.854a2.655 2.655 0 0 0-2.664 2.647c0 8.783 4.55 16.56 12.169 20.807C77.217 76.002 81.363 77 86.409 77c7.353 0 13.735-2.502 18.457-7.235 4.712-4.724 7.382-11.369 7.324-18.23 0-10.343-4.313-17.658-12.235-20.925l10.593-19.264c.214-.39.326-.826.326-1.27V7.648A2.655 2.655 0 0 0 108.21 5m0 2.648v2.428L96.088 32.125c10.431 2.615 13.438 11.023 13.438 19.432.094 11.117-7.894 22.795-23.118 22.795-4.981 0-8.552-1.028-11.747-2.803-6.203-3.457-10.807-10.09-10.807-18.498h14.754c.094 4.298 2.913 6.446 7.424 6.446 4.605 0 7.142-3.456 7.237-7.847-.078-7.757-6.443-8.884-12.673-8.884-1.349 0-2.69.053-3.962.102v-2.242l8.74-18.124H66.391V7.648h41.82'
                }
            }
        ]
    }
};
