/*
 * @Author: zhaoyang
 * @Date: 2022-11-28 17:52:03
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-03-14 23:27:55
 */

import {upperFirst} from 'lodash';
import {mapState} from 'vuex';

import EventUtil from 'easycash/common/util/event';
import {genClickEvent, genEnterEvent} from 'easycash/common/util/event-helper';
import {getKey} from 'easycash/common/util/gen-key';
import {
    getBrowserInfo,
    getUa
} from 'easycash/common/util/get-browser-info';

export default {
    provide() {
        const {uploadClickEvent} = this;

        return {uploadClickEvent};
    },

    data() {
        const withUserKey = this.$route.meta?.business?.withUserKey;
        const userKey = withUserKey ? getKey() : '';

        return {
            userKey
        };
    },

    computed: {
        ...mapState('baseData', ['brand']),

        ...mapState('userStatus', ['status']),

        withUserKey() {
            return this.$route.meta?.business?.withUserKey;
        },

        withUserStatus() {
            return this.$route.meta?.business?.withUserStatus;
        },

        withBrowserInfo() {
            return this.$route.meta?.business?.withBrowserInfo;
        }
    },

    methods: {
        getElementContent(elementContent = {}, uploadQuery = false) {
            const {
                withBrowserInfo,
                withUserKey,
                userKey,
                withUserStatus,
                status = {},
                $route: {query}
            } = this;

            let content = withUserKey ? {...elementContent, userKey} : elementContent;

            if (withUserStatus) {
                content = {...content, ...status};
            }

            if (withBrowserInfo) {
                const browserInfo = getBrowserInfo(getUa());
                content = {...content, ...browserInfo};
            }

            if (uploadQuery) {
                content = {...content, ...query};
            }

            return content;
        },

        async uploadClickEvent(tag, elementContent, useAppMethod) {
            const {getElementContent} = this;

            await EventUtil.uploadEvent(
                genClickEvent(upperFirst(this.brand), tag, getElementContent(elementContent)),
                this.$route,
                this.brand,
                useAppMethod
            );
        },

        async uploadEnterEvent(elementContent, uploadQuery) {
            const {getElementContent, $route, brand} = this;
            await EventUtil.uploadEvent(
                genEnterEvent(upperFirst(brand), $route, getElementContent(elementContent, uploadQuery)),
                $route,
                brand
            );
        },

        uploadEvent(evt) {
            const {brand, $route, getElementContent} = this;
            if (!brand) return;
            let {target} = evt;
            let tag = target.dataset.upload;
            let value = target.dataset.params;

            while (!tag && target) {
                target = target?.parentNode;
                tag = target?.dataset?.upload;
                value = target?.dataset?.params;
            }

            if (!tag) return;

            if (value) {
                try {
                    value = JSON.parse(value);
                } catch (error) {
                    // ignore
                }
            }

            EventUtil.uploadEvent(
                {
                    tag,
                    businessEvent: `${upperFirst(brand)}_${tag}`,
                    elementType: 'Button',
                    eventType: 'C',
                    elementContent: getElementContent(value || {})
                },
                $route,
                brand
            );
        }
    },

    mounted() {
        const {uploadEvent} = this;
        document.body.addEventListener('click', uploadEvent);
    },

    beforeDestroy() {
        const {uploadEvent} = this;
        document.body.removeEventListener('click', uploadEvent);
    }
};
