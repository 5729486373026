/*
 * @Author: qxy
 * @Date: 2019-08-01 15:10:45
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-01 17:09:54
 */

/* eslint-disable max-len */

export default {
    name: 'telephone',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 24 24'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M13.4048362,19 C12.4058876,19 11.4568864,18.6 10.7076749,17.9 L6.11251115,13.3 C4.76393048,11.95 4.61408818,9.75 5.81282655,8.2 L6.91167006,6.8 C7.11145979,6.55 7.36119695,6.45 7.66088154,6.4 C7.96056614,6.4 8.2103033,6.5 8.41009302,6.7 L10.8575172,9.15 C11.2570967,9.55 11.2570967,10.2 10.8575172,10.55 L9.95846342,11.45 C9.90851599,11.5 9.90851599,11.55 9.90851599,11.6 C9.90851599,11.65 9.90851599,11.7 9.95846342,11.75 L12.2560453,14.05 C12.3559402,14.15 12.455835,14.15 12.5557299,14.05 L13.4547837,13.15 C13.6545734,12.95 13.9043106,12.85 14.1540477,12.85 C14.4037849,12.85 14.653522,12.95 14.8533118,13.15 L17.3007359,15.6 C17.5005257,15.8 17.6004205,16.1 17.6004205,16.35 C17.6004205,16.65 17.4505782,16.9 17.2008411,17.1 L15.802313,18.2 L15.5026284,17.8 L15.802313,18.2 C15.1030489,18.75 14.2539426,19 13.4048362,19 Z M7.71082897,7.45 L6.61198547,8.85 C5.71293169,10 5.81282655,11.6 6.86172263,12.6 L11.4568864,17.2 C12.455835,18.2 14.1041003,18.3 15.2029438,17.45 L16.6014719,16.35 L14.1540477,13.9 L13.2549939,14.8 C12.8054671,15.25 12.0063081,15.25 11.5567813,14.8 L9.25919937,12.5 C9.00946221,12.25 8.90956735,11.95 8.90956735,11.65 C8.90956735,11.35 9.05940964,11.05 9.25919937,10.8 L10.1582532,9.9 L7.71082897,7.45 Z M17.5005257,12.05 C17.2008411,12.05 17.0010514,11.85 17.0010514,11.55 C17.0010514,9.05 14.9532066,7 12.455835,7 C12.1561504,7 11.9563607,6.8 11.9563607,6.5 C11.9563607,6.2 12.1561504,6 12.455835,6 C15.5026284,6 18,8.5 18,11.55 C18,11.85 17.8002103,12.05 17.5005257,12.05 Z M15.5026284,12.05 C15.2029438,12.05 15.0031541,11.85 15.0031541,11.55 C15.0031541,10.15 13.8543631,9 12.455835,9 C12.1561504,9 11.9563607,8.8 11.9563607,8.5 C11.9563607,8.2 12.1561504,8 12.455835,8 C14.4037849,8 16.0021027,9.6 16.0021027,11.55 C16.0021027,11.85 15.7523656,12.05 15.5026284,12.05 Z'
                }
            }
        ]
    }
};
