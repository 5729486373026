import { withScope, captureException } from '@sentry/vue';
export var apiErrorReportToSentry = function (request, response) {
    try {
        var _a = request || {}, url_1 = _a.url, method_1 = _a.method, params_1 = _a.params, data_1 = _a.data, deviceToken_1 = _a.deviceToken, cookies_1 = _a.cookies;
        var _b = response || {}, httpCode_1 = _b.httpCode, code_1 = _b.code, traceId_1 = _b.traceId, detail_1 = _b.detail;
        var isFileUploadApi_1 = data_1 instanceof FormData;
        withScope(function (scope) {
            var _a, _b;
            scope.setLevel('info');
            scope.setTags({
                httpCode: httpCode_1,
                isFileUploadApi: isFileUploadApi_1,
                requestUrl: url_1,
                requestMethod: method_1,
                responseCode: code_1,
                deviceToken: deviceToken_1,
                responseTraceId: traceId_1,
                responseMessage: detail_1
            });
            scope.setExtra('hasUserToken', cookies_1 === null || cookies_1 === void 0 ? void 0 : cookies_1.includes('userToken'));
            var ckList = ((_a = cookies_1 === null || cookies_1 === void 0 ? void 0 : cookies_1.split) === null || _a === void 0 ? void 0 : _a.call(cookies_1, ';')) || [];
            scope.setExtra('ckCount', ckList === null || ckList === void 0 ? void 0 : ckList.length);
            scope.setExtra('ckSize', (_b = ckList === null || ckList === void 0 ? void 0 : ckList.map) === null || _b === void 0 ? void 0 : _b.call(ckList, function (ck) { return ck === null || ck === void 0 ? void 0 : ck.length; }));
            scope.setContext('requestParams', { params: params_1, data: data_1 });
            captureException(new Error(url_1));
        });
    }
    catch (error) {
    }
};
