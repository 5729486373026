/*
 * @Author: qxy
 * @Date: 2019-08-01 15:10:45
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-01 17:17:18
 */

/* eslint-disable max-len */

export default {
    name: 'service',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 24 24'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M17.5,10.7037037 L17.5,10.1851852 C17.5,7.33333333 15.25,5 12.5,5 C9.75,5 7.5,7.33333333 7.5,10.1851852 L7.5,10.7037037 C6.65,10.7555556 6,11.4296296 6,12.3111111 L6,14.1259259 C6,15.0074074 6.7,15.7851852 7.6,15.7851852 L8.95,15.7851852 C9.25,15.7851852 9.55,15.5259259 9.55,15.162963 L9.55,11.3259259 C9.55,11.0148148 9.3,10.7037037 8.95,10.7037037 L8.5,10.7037037 L8.5,10.1851852 C8.5,7.9037037 10.3,6.03703704 12.5,6.03703704 C14.7,6.03703704 16.5,7.9037037 16.5,10.1851852 L16.5,10.7037037 L16.1,10.7037037 C15.8,10.7037037 15.5,10.962963 15.5,11.3259259 L15.5,15.162963 C15.5,15.4740741 15.75,15.7851852 16.1,15.7851852 L16.5,15.7851852 L16.5,16.5111111 C16.5,17.0296296 16.1,17.4444444 15.6,17.4444444 L12.85,17.4444444 C12.7,17.1333333 12.35,16.9259259 12,16.9259259 C11.45,16.9259259 11,17.3925926 11,17.962963 C11,18.5333333 11.45,19 12,19 C12.35,19 12.7,18.7925926 12.85,18.4814815 L15.6,18.4814815 C16.65,18.4814815 17.5,17.6 17.5,16.5111111 L17.5,15.7333333 C18.35,15.6814815 19,15.0074074 19,14.1259259 L19,12.3111111 C19,11.4814815 18.35,10.7555556 17.5,10.7037037 Z M8.5,14.7481481 L7.6,14.7481481 C7.3,14.7481481 7,14.4888889 7,14.1259259 L7,12.3111111 C7,12 7.25,11.6888889 7.6,11.6888889 L8.5,11.6888889 L8.5,14.7481481 Z M18,14.1259259 C18,14.437037 17.75,14.7481481 17.4,14.7481481 L16.5,14.7481481 L16.5,11.7407407 L17.4,11.7407407 C17.7,11.7407407 18,12 18,12.362963 L18,14.1259259 Z'
                }
            }
        ]
    }
};
