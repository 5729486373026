/*
 * @Author: zhaoyang
 * @Date: 2022-09-07 14:23:37
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-We 04:49:52
 */

import StageType from 'ssr-common/constant/stage-type';

export default Vue => ({
    async mounted() {
        if (this.getBusinessData) {
            const initVConsole = this.getBusinessData('initVConsole');
            if (!initVConsole) return;
        }

        if ([StageType.dev, StageType.testOverseas, StageType.testIndo, StageType.testMex, StageType.testSea, StageType.testEu].includes(__STAGE__)) { // eslint-disable-line no-undef
            const {default: VConsole} = await import('vconsole');
            Vue.use(new VConsole());
        }
    }
});
