import { setUser } from '@sentry/vue';
import ua from './webview/ua-parsed';
import WebviewInterface from './webview/yqg-webview-interface';
export var setSentryUser = function () {
    try {
        if (!ua.isWebview)
            return;
        WebviewInterface.callHandler('getHashedUserId', '', function (hashedUserId) {
            setUser({ id: hashedUserId });
        });
    }
    catch (error) {
    }
};
