var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { axios } from '@yqg/resource';
import { STAGE } from '@shared/common/constant/stage';
import { isServer, isProd } from '../constant/config';
import { isAndroid, isIOS } from './webview/ua';
var prodIndo = /prod-indo/i.test(STAGE);
var prodMex = /prod-mex-huawei/i.test(STAGE);
var uploadLogMap = {
    'test': 'https://event-tracking-api-test.yangqianguan.com/logMetrics',
    'prod': 'https://event-tracking-api.yangqianguan.com/logMetrics',
    'prod-indo': 'https://event-tracking-api.easycash.id/logMetrics',
    'prod-mex-huawei': 'https://event-tracking-api.mxcred.com/logMetrics'
};
var prodKey = prodIndo ? 'prod-indo' : prodMex ? 'prod-mex-huawei' : 'prod';
var uploadLogKey = isProd() ? prodKey : 'test';
export var api = {
    uploadLog: uploadLogMap[uploadLogKey]
};
export default function initRealTimeEventLog(appName, appId, country) {
    var _this = this;
    if (isServer())
        return function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2];
        }); }); };
    return function (eventName, parameter, logSetting) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, level, ua, url, osType, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = (logSetting !== null && logSetting !== void 0 ? logSetting : {}).level, level = _a === void 0 ? 'INFO' : _a;
                        ua = window.navigator.userAgent;
                        url = window.location.href;
                        osType = 'WEB';
                        if (isAndroid(ua)) {
                            osType = 'ANDROID';
                        }
                        else if (isIOS(ua)) {
                            osType = 'IOS';
                        }
                        return [4, axios.post(api.uploadLog, {
                                level: level,
                                logs: [{
                                        appId: appId,
                                        metricsType: eventName,
                                        measurement: "app_metrics_for_event_log_".concat(appName),
                                        time: +new Date(),
                                        osType: osType,
                                        uuid: parameter === null || parameter === void 0 ? void 0 : parameter.uuid,
                                        message: parameter === null || parameter === void 0 ? void 0 : parameter.message,
                                        parameter: __assign({ url: url, userAgent: ua, requestTime: +new Date() }, parameter)
                                    }]
                            }, {
                                headers: {
                                    'YQG-PLATFORM-SDK-TYPE': appId,
                                    'Country': country
                                },
                                hideLoading: true,
                                hideToast: true
                            })];
                    case 1:
                        _b.sent();
                        return [3, 3];
                    case 2:
                        error_1 = _b.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
}
