/*
 * @Author: qxy
 * @Date: 2019-08-12 16:56:42
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 18:44:11
 */
/* eslint-disable max-len */

export default {
    name: 'card-02',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M26.907 56.909c-5.07 0-5.827-3.365-5.827-5.37v-22.17c0-4.68 4.075-5.186 5.827-5.186 1.836 0 6.106.506 6.106 5.185v22.17c0 2.006-.793 5.37-6.106 5.37m0-52.908c-7.28 0-13.962 2.317-18.813 6.524C2.8 15.116 0 21.536 0 29.09v22.822c0 7.553 2.799 13.973 8.094 18.565C12.945 74.683 19.627 77 26.907 77c7.395 0 14.146-2.316 19.008-6.522C51.205 65.903 54 59.482 54 51.91V29.089c0-7.571-2.796-13.992-8.085-18.567C41.053 6.317 34.302 4 26.907 4m0 55.549c4.929 0 8.741-2.701 8.741-8.01v-22.17c0-5.124-3.812-7.825-8.741-7.825-4.743 0-8.463 2.701-8.463 7.824v22.17c0 5.31 3.72 8.01 8.463 8.01m0-52.908c13.484 0 24.458 8.197 24.458 22.45v22.82c0 14.252-10.974 22.45-24.458 22.45-13.205 0-24.272-8.198-24.272-22.45V29.09c0-14.253 11.067-22.45 24.272-22.45M84.629 4c-.249 0-.5.003-.752.01-14.395.35-23.577 9.8-23.39 24.073.02 1.454 1.193 2.622 2.634 2.622H77.25c1.454 0 2.634-1.19 2.634-2.657 0-2.233.642-3.72 3.78-3.72 1.436 0 2.322.316 2.632.94.744 1.493-.344 5.162-2.328 7.85a3.03 3.03 0 0 0-.063.088L59.459 69.311c-.3.442-.459.965-.459 1.5v2.531C59 74.81 60.179 76 61.634 76h45.732c1.454 0 2.634-1.19 2.634-2.658V58.337c0-1.467-1.18-2.657-2.634-2.657H93.172l10.104-14.17c.026-.036.05-.073.075-.11 5.271-8.234 5.952-18.157 1.776-25.894C101.18 8.194 93.709 4 84.629 4m0 2.658c18.196 0 26.056 18.387 16.509 33.3l-13.106 18.38h19.334v15.004H61.634V70.81L86.08 34.706c3.253-4.407 5.763-13.035-2.417-13.035-3.718 0-6.413 1.781-6.413 6.377H63.12c-.185-14.16 9.296-21.1 20.822-21.382.23-.006.46-.008.687-.008'
                }
            }
        ]
    }
};
