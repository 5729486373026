/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-08-Tu 04:12:41
 */

export default {

    install(Vue) {
        Vue.component('ec-image', () => import('ssr-common/vue/component/ec-image'));
        Vue.component('yqg-md-preview', () => import('ssr-common/vue/component/yqg-md-preview'));
        Vue.component('pa-header', () => import('./pa-header'));
        Vue.component('pa-footer', () => import('./pa-footer'));
        Vue.component('pa-modal', () => import('./pa-modal'));
    }

};
