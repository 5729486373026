/*
 * @Author: wuhuiwang
 * @Date: 2023-06-05 15:20:38
 * @Last Modified by:   wuhuiwang
 * @Last Modified time: 2023-06-05 15:20:38
 */

export default {
    namespaced: true,

    state: () => ({
        yqdContact: {},
        finContact: {}
    }),

    mutations: {
        setYqdContact: (state, yqdContact) => {
            state.yqdContact = yqdContact;
        },

        setFinContact: (state, finContact) => {
            state.finContact = finContact;
        }
    }
};
