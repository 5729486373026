/**
 * @Author: center
 * @Date: 2019/9/24-上午11:27
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:06
 */

export default {
    dev: 'dev',

    test: 'test',
    feat: 'feat',
    prod: 'prod',

    testOverseas: 'test-overseas',

    testIndo: 'test-indo',
    testMex: 'test-mex',
    testSea: 'test-sea',
    testEu: 'test-eu'
};
