/*
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-06-12 19:26:17
 */
import Vue from 'vue';

import App from './app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter();
    const store = createStore();

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
